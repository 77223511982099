@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans&display=swap');
:root{
--mainColor: lightcoral; /* Default value */
}
.bubbleDiv{
  width: 100%;
  height: auto;
  background-color: var(--mainColor);
  border-radius: 10px;
  padding: 10px
}
.bg {
    opacity: 1; /* Full visibility */
    background: rgba(222, 213, 229, 0.73);
    width: 400px; 
    height: 100%; 
    visibility: visible;
    justify-content: center;
}
  
.stats-lander {
    position: relative;
    opacity: 1; /* Full visibility */
    background: rgba(140, 104, 170, 0.73);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 00px 00px;
    width: 100%;
    height: auto;
    padding-bottom: 10px;
    visibility: visible;
}
  
.stats-oval {
    display: flex;
    opacity: 1; /* Full visibility */
    width: 80%;
    height: 35px;
    background: #724598;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    margin: 0 auto;    
    visibility: visible;
    align-items: center;  
    justify-content: center;
    text-justify: center;
}
#dropdown-circle-toggle::after {
  display: none; /* Hide the caret */
}
.stats-label {
    font-family: 'Secular One', Georgia, 'Times New Roman', Times, serif;
    font-size: 21px;
    color: white;
    opacity: 1; /* Full visibility */
    visibility: visible;
    text-align: center; /* Center the label text */
}
  
.hashtag-label {
    font-family: 'Secular One', Georgia, 'Times New Roman', Times, serif;
    font-size: 12px;
    color: white;
    opacity: 1; /* Full visibility */
    visibility: visible;
}
.the-input {
  font-family: 'Secular One', Georgia, 'Times New Roman', Times, serif;
  border-radius: 4px;
  background: transparent;/* Dark background to match the label */
  color: white; /* White text for contrast */
  outline: none;
  height: 25px;
  text-align: center;
  border-width: 1px;
  border-color: white;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.hashtag-input:focus {
  background: transparent;
}

.hashtag-input::placeholder {
  color: white; /* Lighter gray for placeholder text */
  opacity: 50%;
  
}

.centered-div {
    padding-top: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    visibility: visible;
}

.car-label {
    font-family: 'Kumbh Sans', Georgia, 'Times New Roman', Times, serif;
    font-weight: bolder;
    letter-spacing: 1px;
    font-size: 25px;
    color: white;
    opacity: 1; /* Full visibility */
    margin-bottom: 5px;   
    margin-top: 5px;
    visibility: visible;
}

.car-info {
    width: 80%;
    max-width: 600px;
    margin-bottom: 20px;
    background-color: #a34242;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    visibility: visible;
}

.rounded-bottom-img {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 100%;
    height: auto;
    visibility: visible;
}
  
.car-img {
    margin-bottom: -25px;
    opacity: 1; /* Full visibility */
    width: 100%;
    height: auto;
    display: block;
    border-radius: 10px;
    visibility: visible;
}

/* The remaining styles unchanged, as they didn't have any opacity issues */

  .header {
    display: flex;
    justify-content: space-between;
  }
  
  .specs {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }
  
  .specs h2 {
    font-size: 1.5em;
    margin: 0;
  }
  
  .hashtags p {
    text-align: center;
    margin-top: 10px;
    font-size: 1.2em;
  }
  
  .buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .button {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #f0f0f0;
  }
  
  .performance-part {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  
  .performance-part img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  
  .performance-part h3 {
    margin: 0;
    font-size: 1.2em;
  }
/* No changes needed here */
.toggle-container {
    display: flex; /* Use flexbox for layout */
    justify-content: center; /* Center items horizontally */
  }
  
  .toggle-button {
    width: 100%; /* Ensures responsiveness */
    display: inline-block; /* Makes the label inline */
    justify-content: center;
    display: flex;
  }
  
  .toggle-button input {
    opacity: 0; /* Hide the default checkbox */
    width: 0; /* Prevents size issues */
    height: 0; /* Prevents size issues */
  }
  
  .slider {
    width: 350px; /* Ensures responsiveness */
    height: 50px; /* Maintains aspect ratio */
    cursor: pointer; /* Changes cursor on hover */
    background-color: white; /* Default background color */
    transition: .4s; /* Smooth transition */
    border-radius: 34px; /* Rounded corners */
    display: flex; /* Use flexbox for the inner labels */
    align-items: center; /* Center labels vertically */
    justify-content: space-between;
    position: relative; /* Ensure child elements can be positioned */
    margin: 0 auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  }
  
  /* Circle inside the toggle */
  .slider:before {
    width: 200px; /* Ensures responsiveness */
    height: 50px; /* Maintains aspect ratio */
    content: ""; /* Empty content */
    position: absolute;
    background-color: var(--mainColor);
    transition: .4s; /* Smooth transition */
    border-radius: 34px; /* Rounded corners */
  }
  
  /* Move the circle to the right when checked */
  input:checked + .slider:before {
    transform: translateX(150px); /* Move circle */
  }

  
  /* Style for the toggle labels */
  .toggle-label {
    font-family: "Secular One", Georgia, 'Times New Roman', Times, serif;
    font-size: 20px;
    color: white;
    transition: font-size 0.4s ease, color 0.4s ease; 
    transition: 0.4s ease;

    position: absolute;
    width: 50%; /* Adjust width so the label takes up half the slider */
    text-align: center; /* Center the label text */
  }
  
  .toggle-label.off {
    left: 10px; /* Position "Cosmetics" on the left */
  }
  
  .toggle-label.on {
    right: 10px; /* Position "Performance" on the right */
  }
  
  /* Show the appropriate label based on the toggle state */
  .toggle-button input:checked + .slider .toggle-label.on {
    color: white; /* Show 'On' when checked */
    font-size: 20px;
  }
  
  .toggle-button input:checked + .slider .toggle-label.off {
    color: black; /* Show 'On' when checked */
    font-size: 17px;
    translate: -10px;
  }
  
   .toggle-button input:not(:checked) + .slider .toggle-label.off {
    color: white;
    font-size: 20px;


  }
  
  .toggle-button input:not(:checked) + .slider .toggle-label.on {
    color: black; 
    font-size: 17px;
    translate: 10px;


  }
  #conditional-button {
    display: block; /* Initially hidden */
    width: 100px;
    height: 100px;
    position: fixed; /* Position relative to the viewport */
    left: 25px; /* Align to the left */
    bottom: 25px; /* Align to the bottom */
    font-size: 23px; /* Button text size */
    color: #000000; /* Button text color */
    background: #724598;
    border: 1px; /* Remove default border */
    border-radius: 100px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: background-color 0.3s ease; /* Smooth transition */
}

#conditional-button:hover {
  background: grey;
}
.vis {
  visibility: visible;
}

.settings-drop {
  position: fixed; /* Position relative to the viewport */
  margin: 0; /* No need for auto margins here */
  right: 0; /* Align to the right of the viewport */
  top: 25px; /* Position from the top */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth background-color transition */
}

.settings-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10; /* z-index should be an integer without units */
}


.settings-drop:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Light background on hover */
  border-radius: 20%; /* Keep the circular shape on hover */
}
.file-upload {
  display: flex;
  align-items: center;
  margin: 0 auto;
}


/* Hide the default file input */
.file-input {
  display: none;
}

/* Style the label to look like a button */
.file-label {
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
  margin: 0 auto;
}

.file-label:hover {
  background-color: #0056b3;
}

/* Style the file name display */
.file-name {
  font-size: 14px;
  color: #555;
}
.popup-content {
  width: 400px;
  height: auto; /* Set the width of the popup */
  padding: 10px; /* Optional: Add padding for better spacing */
  background-color: white; /* Background color */
  border-radius: 8px; /* Optional: Rounded corners */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  justify-content: center;
  display: flex /* Optional: Shadow for depth */
  
}

/* Ensure the inputs and button fit well within the popup */
.popup-content input {
  width: 100%; /* Make input fields full width */
   margin: 10px 0; /* Spacing betrween inputs */
  padding: 8px; /* Padding inside input fields */
}

.popup-content-button {
  padding: 10px 15px; /* Padding for button */
  background-color:rgb(110,117,124); /* Button background color */
  color: white; /* Button text color */
  border: none;
  border-radius: 5px; /* Rounded corners */
   cursor: pointer; /*Pointer cursor on hover */
}

.popup-content button:hover {
  background-color: rgb(50,50,50); /* Darker shade on hover */
}
.plus-mod{
  width: 348px;
  height: 60px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  align-content: center;
  justify-content: center;
  display: flex;
  opacity: 1;
  border: 0;
}
.plus-mod:hover{
  background: rgb(242, 242, 242)
}
.mod{
  width: 348px;
  height: 60px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  align-content: center;
  justify-content: center;
  display: flex;
  opacity: 1;
  border: 0;
  margin-bottom: 10px;
}
.popup-title-div{
  width: 100%;
  padding: 10px; /* Optional: Add padding for better spacing */
  margin-right: 10px;
  background-color: white; /* Background color */
  border-radius: 8px; /* Optional: Rounded corners */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Optional: Shadow for depth */
  text-align: left;
  height: auto
}
.mod-fade-enter {
  opacity: 0;
  transform: translateY(20px);
}
.mod-fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}
.mod-fade-exit {
  opacity: 1;
  transform: translateY(0);
}
.mod-fade-exit-active {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 500ms, transform 500ms;
}

.mod-head{
  font-size: 18px;
  font-weight: bold;
}
.mod-descrip{
  font-size: 12px;
  
}
.otherModInput[disabled] {
  background-color: #d3d3d3; /* Light gray background */
  color: #7a7a7a; /* Darker gray text */
  cursor: not-allowed; /* Show a not-allowed cursor */
  border: 1px solid #ccc; /* Optional: border style */
}
.otherModInput {
  background-color: #ededed; /* Light gray background */
  color: #7a7a7a; /* Darker gray text */
  cursor: pointer;
  border: 1px solid #ccc; /* Optional: border style */
}
.fade-button-edit {
  font-size: 25px;
  border: none;
  font-family: 'Secular One', Georgia, 'Times New Roman', Times, serif;
  border-radius: 15px;
  background-color: white;
  color: black;
  cursor: pointer;
  opacity: 0.9;  /* Start fully visible */
  transition: opacity 1s ease-in-out;  /* Smooth transition for opacity */
}

.fade-button-edit.hidden {
  opacity: 0.3;  /* When the button has the 'hidden' class, it becomes invisible */
}